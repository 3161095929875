import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./page/home/Home";
import Test from "./page/home/Test";
import ReactGA from "react-ga4";
import AnalyticsDashboard from "./page/AnalyticsDashboard";
import FunnelsComponent from "./page/home/FunnelsComponent";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
};

function AppRoutes() {
  usePageTracking();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/test" element={<Test />} />
      <Route path="/admin0703" element={<AnalyticsDashboard />} />
      <Route path="/adminDB0703" element={<FunnelsComponent />} />
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

export default App;
