import React, { useState } from "react";

function HomeTwo() {
  // 애니메이션 실행 여부를 추적하는 상태
  // const [animateOnce, setAnimateOnce] = useState(false);

  // 마우스 오버 이벤트 핸들러
  // const handleMouseEnter = () => {
  //   if (!animateOnce) {
  //     // 애니메이션이 아직 실행되지 않았다면 실행
  //     setAnimateOnce(true);
  //   }
  // };

  return (
    <section
      className="eightThContainer"
      // onMouseEnter={handleMouseEnter}
    >
      <div className="sectionInner">
        <h2 className="sectionTitle">
          퍼널스, 딱 <span>3</span>
          <span>가</span>
          <span>지</span>만<br className="mobile-br" /> 기억해주시면 됩니다{" "}
        </h2>
        <div className="eightthBodysection fadeInUp">
          {/* <div className={`eightthBodysection ${animateOnce ? "fadeInUp" : ""}`}> */}

          <div
            className="eightthSection"
            style={{
              background: `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(15, 42, 83, 1) 90%,
          rgba(0, 38, 96, 1) 100%
        ), url(${process.env.PUBLIC_URL}/Images/22/img_01.png) center/cover`,
              color: "#000",
              /* 추가적인 스타일을 필요에 따라 여기에 추가할 수 있습니다. */
            }}
          >
            <h3 className="eightthTitle">· 치과 마케팅 집중 ·</h3>
            <div className="eightthLine"></div>
            <p className="eightDescription">
              10년간 치과 마케팅만 집중해
              <br className="pc-br" /> 시장 특성과
              <br className="mobile-br" />
              고객의 니즈를
              <br className="pc-br" /> 정확하게 파악하고 있습니다.
            </p>
          </div>
          <div
            className="eightthSection"
            style={{
              background: `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(15, 42, 83, 1) 90%,
          rgba(0, 38, 96, 1) 100%
        ), url(${process.env.PUBLIC_URL}/Images/22/img_03.png) center/cover`,
              color: "#000",
              /* 추가적인 스타일을 필요에 따라 여기에 추가할 수 있습니다. */
            }}
          >
            <h3 className="eightthTitle">· 압도적 레퍼런스 ·</h3>
            <div className="eightthLine"></div>
            <p className="eightDescription">
              월 매출 30억 치과와 초기 개원 <br className="pc-br" />
              14개월만에
              <br className="mobile-br" /> 월 7억 돌파한 경험.{" "}
              <br className="pc-br" />
              타사와 비교 불가합니다.
            </p>
          </div>
          <div
            className="eightthSection"
            style={{
              background: `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 0%,
          rgba(15, 42, 83, 1) 90%,
          rgba(0, 38, 96, 1) 100%
        ), url(${process.env.PUBLIC_URL}/Images/22/img_02.png) center/cover`,
              color: "#000",
              /* 추가적인 스타일을 필요에 따라 여기에 추가할 수 있습니다. */
            }}
          >
            <h3 className="eightthTitle">· 마케팅 철학 ·</h3>
            <div className="eightthLine"></div>
            <p className="eightDescription">
              예약과 매출로 이어지는
              <br className="pc-br" /> ‘성과기반’ 마케팅만
              <br className="mobile-br" />
              <br className="pc-br" /> 고민하고 실행합니다.
            </p>{" "}
          </div>
        </div>
        <div>
          <p className="eightFooter">
            <span>퍼널스는 오직 치과</span>만,
            <br />
            수치화 할 수 있는 <span>퍼포먼스 마케팅</span>으로
            <br className="mobile-br" /> 확실하게 <span>매출 상승</span>{" "}
            시켜드립니다.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HomeTwo;
