import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LogoSlide() {
  const settings1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 7.25,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
  };

  const images = [];
  for (let i = 1; i <= 10; i++) {
    images.push(
      `${process.env.PUBLIC_URL}/Images/9/logos/logo_1_${String(i).padStart(
        2,
        "0"
      )}.png`
    );
  }
  const images2 = [];
  for (let i = 1; i <= 10; i++) {
    images2.push(
      `${process.env.PUBLIC_URL}/Images/9/logos/logo_2_${String(i).padStart(
        2,
        "0"
      )}.png`
    );
  }
  return (
    <div className="slider-container">
      <Slider {...settings1}>
        {images.map((imgSrc, index) => (
          <div key={index} className="FouthCard">
            <img src={imgSrc} alt={`img_${index + 1}`} />
          </div>
        ))}
      </Slider>
      <Slider {...settings2}>
        {images2.map((imgSrc, index) => (
          <div key={index} className="FouthCard">
            <img src={imgSrc} alt={`img_${index + 1}`} />
          </div>
        ))}
      </Slider>
      <style jsx>{``}</style>
    </div>
  );
}

function HomeNine() {
  return (
    <section className="section9">
      <div className="sectionInner">
        <h2 className="sectionTitle">신규 입점 가능 지역</h2>
        <h3>
          퍼널스 지역 내 압도적 1위 치과를 만들기 위해{" "}
          <br className="moblie-br" /> <span>지역별로 한 곳의 치과와만</span>{" "}
          협력하고 있습니다.
        </h3>
        <div className="mapSpecial">
          <div className="map">
            <img
              src={`${process.env.PUBLIC_URL}/Images/9/map.png`}
              alt="한반도"
            />
          </div>
          <div className="special">
            <div>
              <h4>
                부산/경남권
                <br className="mobile-br" /> 신규 입점 시
                <br className="mobile-br" /> <span>특별혜택</span>
              </h4>
              <ul>
                <li>1. 지역 마케팅 분석 및 컨설팅 무상 제공</li>
                <li>
                  2. 랜딩페이지/솔루션 무상 제공<span>(30~50만원 상당)</span>
                </li>
                <li>
                  3. 의료심의 제작, 승인, 세팅<span>(심의비용 별도)</span>
                </li>
              </ul>
            </div>
            <p>*3개월 단위 계약 기간</p>
          </div>
        </div>
        {/* <LogoSlide /> */}
      </div>
    </section>
  );
}
export default HomeNine;
