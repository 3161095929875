import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: "block", color: "black" }}
    onClick={onClick}
  />
);

function HomeSevenMobile() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
  };

  return (
    <section className="tenContainer sectionInner">
      <div className="sectionHi">
        <p className="sectionSubTitle">서비스 상품</p>
        <h1 className="sectionTitle">
          퍼널스와 함께한
          <br className="mobile-br" /> 파트너들의 실제 후기
        </h1>
        <Slider {...settings} className="mobileTenSlider">
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL + "/Images/kakao/kakaoIcon_dollar.png"
                }
                alt="달러 아이콘"
              />
              개원 6개월 월평균 2억 매출 달성
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao1.png"}
                alt="개원 6개월 월평균 2억 매출 달성"
              />
            </div>
          </div>
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL + "/Images/kakao/kakaoIcon_chart.png"
                }
                alt="차트아이콘"
              />
              월별 치과 운영 성과 리포트 보고
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao6.png"}
                alt="월별 치과 운영 성과 리포트 보고"
              />
            </div>
          </div>
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL + "/Images/kakao/kakaoIcon_naver.png"
                }
                alt="네이버 아이콘"
              />
              ‘네이버플레이스’로 인한 신환증가
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao2.png"}
                alt="네이버플레이스로 인한 신환증가"
              />
            </div>
          </div>
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL + "/Images/kakao/kakaoIcon_print.png"
                }
                alt="프린트물 아이콘"
              />
              업무별 최적화된 메뉴얼 제공
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao5.png"}
                alt="업무별 최적화된 메뉴얼 제공"
              />
            </div>
          </div>
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL +
                  "/Images/kakao/kakaoIcon_magnifier.png"
                }
                alt="돋보기아이콘"
              />
              고객 관리 최적화로 효율성 증대
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao3.png"}
                alt="카카오이미지3"
              />
            </div>
          </div>
          <div className="mobileTenCard">
            <p>
              <img
                className="mobileKakaoIcon"
                src={
                  process.env.PUBLIC_URL + "/Images/kakao/kakaoIcon_graph.png"
                }
                alt="그래프아이콘"
              />
              데이터 기반 경영 컨설팅 진행
            </p>
            <div className="mobileImgBox">
              <img
                className="mobileTenImgStyle"
                src={process.env.PUBLIC_URL + "/Images/kakao/kakao4.png"}
                alt="데이터 기반 경영 컨설팅 진행"
              />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
}

export default HomeSevenMobile;
