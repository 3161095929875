function Home3new() {
  return (
    <section className="ThirdNewBox">
      <div className="sectionInner">
        <div className="ThirdNewBoxSet">
          <div className="sectionSubTitle">MSOM</div>
          <h2 className="sectionTitle">
            단순 광고대행만 해본 회사는
            <br className="mobile-br" />
            <br className="pc-br" />
            <span className="colorBlue3nwe">
              치과의 니즈를 파악할 수 없습니다
            </span>
          </h2>
          <img
            src={process.env.PUBLIC_URL + "/Images/2/benDiagram.png"}
            alt="벤다이어그램"
            className="ThirdNewBoxImg"
          />
          <div className="thirdNewDetail">
            퍼널스는 MSO를 운영하며 축적한 치과에 대한 깊은 이해를{" "}
            <br className="mobile-br" />
            온라인 마케팅에 접목하여,
            <br className="pc-br" />
            <span className="thirdNewDetailSpan">
              독자적인 철학인 MSOM <br className="mobile-br" />
              (Management Service and Online Marketing)을 만들어냈습니다.
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home3new;
