import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import React from "react";
function HomeThreeTest() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const cardStyle = {
    width: "356px", // 카드의 폭을 356px로 설정
    margin: "auto", // 카드를 가운데 정렬
  };
  return (
    <section className="Third">
      <div className="sectionInner">
        <div className="ThirdHeader">
          <h1 className="thirdMaintitle">
            성공 레퍼런스
            <br />
            퍼널스는 최종 매출로 모든 걸 증명합니다
          </h1>
          <h6>매출이 오르지 않을 경우 100% 환불해드립니다.</h6>
        </div>
        {/* --------------------------------------- */}
        {/* <div className="ThirdBody"> */}
        {/* <Graph /> */}
        <div className="GraphImgCenter">
          <img
            src={process.env.PUBLIC_URL + "/Images/funnelsGraph.gif"}
            alt="퍼널스"
          />
        </div>
        {/* </div> */}
        {/* --------------------------------------- */}
        <div className="slider-container">
          {/* <div className="ThirdFooter"> */}
          <Slider {...settings}>
            <div className="card" style={cardStyle}>
              <img
                src={process.env.PUBLIC_URL + "/Images/2/CARDA.png"}
                alt="CARDA 이미지"
              />
            </div>
            <div className="card" style={cardStyle}>
              <img
                src={process.env.PUBLIC_URL + "/Images/2/CARDB.png"}
                alt="CARDB 이미지"
              />
            </div>
            <div className="card" style={cardStyle}>
              <img
                src={process.env.PUBLIC_URL + "/Images/2/CARDC.png"}
                alt="CARDC 이미지"
              />
            </div>
            <div className="card" style={cardStyle}>
              <img
                src={process.env.PUBLIC_URL + "/Images/2/CARDD.png"}
                alt="CARDD 이미지"
              />
            </div>
            <div className="card" style={cardStyle}>
              <img
                src={process.env.PUBLIC_URL + "/Images/2/CARDE.png"}
                alt="CARDE 이미지"
              />
            </div>
            <div className="card" style={cardStyle}>
              <div className="cardDiv">
                <div className="cardClean">
                  <p className="highlighted-text">
                    <span className="highlight">퍼널스</span>와 함께 할<br />{" "}
                    파트너 원장님을 찾습니다.
                  </p>
                  <button className="partnerBtn">파트너 신청하기</button>
                </div>
              </div>
            </div>
          </Slider>
        </div>{" "}
        <style jsx>{`
          .slick-slider {
            margin: 0 15px;
          }
          .Third {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #101014;
            padding-bottom: 100px;
            object-fit: cover;
            .sectionInner {
              padding-bottom: 200px;
              .GraphImgCenter {
                margin: 90px 0px 158px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                 {
                  /* img {
                  width: 1121px;
                } */
                }
              }
            }
          }
          .ThirdHeader {
            color: #ffffff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .thirdMaintitle {
              text-align: center;
              font-size: 48px;
              color: #ffffff;
              font-weight: 700;
              padding-bottom: 66px;
            }
            h6 {
              font-size: 26px;
              font-weight: 700;
            }
          }

          .card {
            .highlighted-text {
              margin: 50px 0 18px;
              color: #ffffff;
              font-size: 1.7rem;
            }
            .highlighted-text .highlight {
              color: #0070f3;
            }
            .cardDiv {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px dotted white;
              height: 230px;
              border-radius: 20px;
              margin-right: 3vw;
              .cardClean {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                  text-align: center;
                  margin-top: 30px;
                }
              }
            }
          }
          .partnerBtn {
            color: #ffffff;
            background-color: #3288ff;
            width: 171px;
            height: 40px;
            border-radius: 20px;
            border: none;
            cursor: pointer;
            font-size: 14px;
            font-weight: 700;
          }

          .slick-dots li button:before {
            color: white;
          }
          .slick-dots li.slick-active button:before {
            color: white;
          }
        `}</style>
      </div>
    </section>
  );
}

export default HomeThreeTest;
