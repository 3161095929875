import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";

// 측정 ID로 초기화
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

ReactGA.initialize(
  measurementId
  // { debug: true }
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
