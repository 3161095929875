import { useEffect, useState } from "react";
import Footer from "../../layout/Footer";
import Layout from "../../layout/Layout";
import HomeFirst from "./HomeFirst";
import HomeSecond from "./HomeSecond";
import HomeThree from "./HomeThree";
import Home3 from "./Home3";
import HomeLast from "./HomeLast";
import Homefive from "./Homefive";
import Home5th from "./Home5th";
import HomeEight from "./HomeEight";
import HomeFour from "./HomeFour";
import HomeTwo from "./HomeTwo";
import HomeSix from "./HomeSix";
import Home10th from "./Home10th";
import HomeSeven from "./HomeSeven";
import HomeNine from "./HomeNine";
import Spinner from "./Spinner";
import "../../style/style.scss";
import HomeSevenMobile from "./HomeSevenMobile";
import Home3new from "./Home3new";

function Home() {
  const [isRendered, setIsRendered] = useState(false);
  const [isAppleDevice, setIsAppleDevice] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1120);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const appleDevice = /iPhone|iPad|iPod|Macintosh/.test(userAgent);
    setIsAppleDevice(appleDevice);

    const timer = setTimeout(() => {
      setIsRendered(true);
    }, 1370);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1120);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!isRendered) return;

    const sections = document.querySelectorAll(".sectionInner");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.3 }
    );

    sections.forEach((section) => {
      observer.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [isRendered, isMobile]); // isMobile 상태가 변경될 때마다 실행

  if (!isRendered) {
    return <Spinner />;
  }

  return (
    <div className={`mainHome ${isAppleDevice ? "iphone" : ""}`}>
      <Layout />
      <HomeFirst />
      <HomeSecond />
      {isMobile ? <Home3 /> : <HomeThree />}
      <Home3new />
      <HomeFour />
      {/* <Homefive /> */}
      <HomeSix />
      {isMobile ? <HomeSevenMobile /> : <HomeSeven />}
      <HomeEight />
      <HomeNine />
      <Home10th />
      {/* <Home1122 /> */}
      {/* <HomeSecond /> */}
      {/* <Home5th /> */}
      {/* <HomeLast /> */}
      <Footer />
      {/* <style jsx>{``}</style> */}
    </div>
  );
}

export default Home;
