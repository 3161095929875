import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import axios from "axios";

// 데이터 시각화 옵션 설정
const options = {
  title: "Page Views",
  hAxis: { title: "Date" },
  vAxis: { title: "Views" },
  legend: "none",
};

const AnalyticsDashboard = () => {
  const [data, setData] = useState([["Date", "Views"]]);

  useEffect(() => {
    // 여기에 Google Analytics Reporting API 호출 코드를 작성합니다.
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://analyticsreporting.googleapis.com/v4/reports:batchGet",
          {
            reportRequests: [
              {
                viewId: "YOUR_VIEW_ID",
                dateRanges: [{ startDate: "30daysAgo", endDate: "today" }],
                metrics: [{ expression: "ga:pageviews" }],
                dimensions: [{ name: "ga:date" }],
              },
            ],
          },
          {
            headers: {
              Authorization: `Bearer YOUR_ACCESS_TOKEN`,
              "Content-Type": "application/json",
            },
          }
        );

        const rows = response.data.reports[0].data.rows.map((row) => [
          row.dimensions[0],
          parseInt(row.metrics[0].values[0], 10),
        ]);

        setData((prevData) => [...prevData, ...rows]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    window.open(
      "https://analytics.google.com/analytics/web/",
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div className="Anacontainer">
      <h1>Google Analytics Dashboard</h1>
      <div className="chart">
        <Chart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={data}
          options={options}
        />
      </div>
      <button onClick={handleClick} className="link-button">
        Go to Google Analytics Dashboard
      </button>
      <style jsx>{`
        .Anacontainer {
          padding: 20px;
          font-family: Arial, sans-serif;
          h1 {
            text-align: center;
            color: #333;
            margin-bottom: 20px;
          }
          .chart {
            margin: 0 auto;
            width: 80%;
            max-width: 800px;
          }
          .link-button {
            display: block;
            width: 300px;
            margin: 20px auto;
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            text-align: center;
            text-decoration: none;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            transition: background-color 0.3s;
            cursor: pointer;
          }
          .link-button:hover {
            background-color: #45a049;
          }
        }
      `}</style>
    </div>
  );
};

export default AnalyticsDashboard;
