function HomeEight() {
  return (
    <section className="funnelsMessase">
      <div className="sectionInner">
        <p className="sectionSubTitle">서비스 상품</p>
        <h2 className="sectionTitle">퍼널스가 제안합니다.</h2>
        <p className="funnelsText">
          개원을 준비하고 계신 예비 원장님,
          <br className="pc-br" />
          <br className="mobile-br" />
          이번 달 신환수와 매출 증대가 꼭 필요한 치과,
          <br className="pc-br" />
          <br className="mobile-br" />
          진료의 퀄리티는 유지하면서도
          <br className="mobile-br" /> 경영에 집중하고 싶으신 대표 원장님,
          <br className="pc-br" />
          <span>그렇다면 퍼널스를 추천드립니다.</span>
        </p>
        <div className="funnelsSurviesItems">
          <div
            className="funnelsSurviesItem"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/Images/8/bg_open.png)`,
            }}
          >
            <div>
              <h3>
                개원/리뉴얼 오픈
                <br />
                마케팅
              </h3>
            </div>
          </div>
          <div
            className="funnelsSurviesItem"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/Images/8/bg_online.png)`,
            }}
          >
            <div>
              <h3>온라인 마케팅</h3>
            </div>
          </div>
          <div
            className="funnelsSurviesItem"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/Images/8/bg_mso.png)`,
            }}
          >
            <div>
              <h3>MSO</h3>
              <p>
                Management
                <br />
                Service
                <br />
                Organization
              </p>
            </div>
          </div>
        </div>
        <div className="funnelsMenu">
          <div>
            <div>퍼포먼스 베이직</div>
            <div>
              <span>월 500만원 </span>(최소 3개월 계약)
            </div>
          </div>
          <div>
            <div>퍼포먼스 스탠다드</div>
            <div>
              <span>월 1500만원 </span>
              <p>(1개월 단위 계약 가능, 전문 콜센터 운영 포함)</p>
            </div>
          </div>
          <div>
            <div>퍼포먼스 프리미엄</div>
            <div>
              <span>월 2500만원 </span>
              <p>(맞춤형 컨설팅 및 프리미엄 서비스 제공)</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeEight;
