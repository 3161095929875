import { useEffect, useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";

function Layout() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1120);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleModalClick = (event) => {
    if (event.target.className === "layOutmodal") {
      toggleModal();
    }
  };
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollToContact = () => {
    scroll.scrollTo(document.getElementById("contactSection").offsetTop);
  };

  const scrollToHeight = () => {
    const scrollTarget = window.innerWidth <= 1120 ? 1500 : 2200;
    scroll.scrollTo(scrollTarget);
    toggleModal();
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector("header");
      const logo = header.querySelector("img");

      if (window.innerWidth <= 576 && menuOpen) {
        setTimeout(() => {
          logo.src = `${process.env.PUBLIC_URL}/Images/funnels_logo_color.png`;
        }, 350);
      } else if (window.scrollY > 100) {
        setTimeout(() => {
          header.classList.add("scrolled");
          logo.src = `${process.env.PUBLIC_URL}/Images/funnels_logo_color.png`;
        }, 350);
      } else if (window.scrollY <= 100) {
        setTimeout(() => {
          header.classList.remove("scrolled");
          logo.src = `${process.env.PUBLIC_URL}/Images/funnels_logo_white.png`;
        }, 90);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => window.removeEventListener("scroll", handleScroll);
  }, [menuOpen]);

  return (
    <header>
      <div className={`headerInner ${menuOpen ? "open" : ""}`}>
        <h1>
          <img
            src={`${process.env.PUBLIC_URL}/Images/funnels_logo_white.png`}
            alt="funnels"
          />
        </h1>
        <nav>
          <ul>
            <li>
              <Link
                to="aboutSection"
                spy={true}
                smooth={true}
                duration={700}
                onClick={toggleMenu}
              >
                ABOUT US
              </Link>
            </li>
            <li>
              <Link
                to="workSection"
                spy={true}
                smooth={true}
                duration={700}
                onClick={toggleMenu}
              >
                WORK
              </Link>
            </li>
            <li>
              <Link
                to="contactSection"
                spy={true}
                smooth={true}
                duration={700}
                onClick={toggleMenu}
              >
                CONTACT
              </Link>
            </li>
          </ul>
        </nav>
        <div className="layOutFixedButtons">
          {!isMobile && (
            <button className="layOutButton" onClick={scrollToTop}>
              <img
                src={`${process.env.PUBLIC_URL}/Images/2/aside_top.png`}
                alt="Scroll to Top"
              />
            </button>
          )}

          <button className="layOutButton" onClick={scrollToContact}>
            <img
              src={`${process.env.PUBLIC_URL}/Images/2/asdie_help.png`}
              alt="Scroll to Contact"
            />
          </button>
          <button className="layOutButton" onClick={scrollToHeight}>
            <img
              src={`${process.env.PUBLIC_URL}/Images/2/aside_ref.png`}
              alt="Scroll to Height"
            />
          </button>
        </div>
        <div
          className={`hamburger ${menuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      {isModalOpen && (
        <div className="layOutmodal" onClick={handleModalClick}>
          <div className="layoutmodalContent">
            <img
              src={`${process.env.PUBLIC_URL}/Images/2/longIMG.png`}
              alt="Long Image"
            />
            <button className="closeButton" onClick={toggleModal}>
              ×
            </button>
          </div>
        </div>
      )}
    </header>
  );
}

export default Layout;
