import React, { useState, useEffect } from "react";
import axios from "axios";

const FunnelsComponent = () => {
  const [funnels, setFunnels] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedFunnel, setSelectedFunnel] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/main/funnels");
        setFunnels(response.data);
      } catch (error) {
        console.error("Error fetching funnels:", error);
      }
    };
    fetchData();
  }, []);

  const handleFunnelClick = (funnel) => {
    setSelectedFunnel(funnel);
    setModalVisible(true);
  };

  const Modal = ({ funnel }) => (
    <div className="modalBackground">
      <div className="modalContent">
        <h2>메모 상세 정보</h2>
        <p className="memoContent">{funnel.memo}</p>
        <button onClick={() => setModalVisible(false)}>닫기</button>
      </div>
    </div>
  );

  return (
    <div className="databaseContainer">
      <h1>퍼널 리스트</h1>
      <div className="tableHeader">
        <span>이름</span>
        <span>지역</span>
        <span>전화번호</span>
        <span>서비스</span>
        <span>수익</span>
        <span>생성일</span>
        <span>신청인</span>
        <span>개인정보 동의</span>
      </div>
      <ul>
        {funnels.map((funnel) => (
          <li key={funnel.id} onClick={() => handleFunnelClick(funnel)}>
            <span>{funnel.name}</span>
            <span>{funnel.region}</span>
            <span>{funnel.phone}</span>
            <span className="service">{funnel.service}</span>
            <span>₩{funnel.revenue}</span>
            <span>{new Date(funnel.created_at).toLocaleDateString()}</span>
            <span>{funnel.apply}</span>
            <span>{funnel.privacy}</span>
          </li>
        ))}
      </ul>
      {modalVisible && <Modal funnel={selectedFunnel} />}
      <style jsx>{`
        .databaseContainer {
          max-width: 1200px;
          margin: auto;
          padding: 20px;
          background: #fff;
          border-radius: 8px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
          color: #343a40;
          .tableHeader {
            display: grid;
            grid-template-columns: 0.8fr 0.5fr 1fr 3fr 1fr 1fr 1fr 1fr;
            background: #007bff;
            color: #ffffff;
            padding: 10px;
            margin-bottom: 10px;
            font-weight: bold;
            text-align: center;
            border-radius: 4px;
          }
          h1 {
            text-align: center;
            color: #007bff;
            margin-bottom: 20px;
            font-size: 24px;
          }
          ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
          }
          li {
            display: grid;
            grid-template-columns: 0.8fr 0.5fr 1fr 3fr 1fr 1fr 1fr 1fr;
            gap: 10px;
            padding: 12px;
            margin-bottom: 8px;
            background: #f8f9fa;
            border-radius: 4px;
            cursor: pointer;
            align-items: center;
            text-align: center;
            transition: background-color 0.3s;
          }
          li:hover {
            background-color: #e9ecef;
          }
          .modalBackground {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .modalContent {
            background: white;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            width: 500px;
            max-width: 90%;
            text-align: center;
          }
          .memoContent {
            margin: 10px 0;
            padding: 10px;
            background-color: #f0f0f0;
            border-radius: 4px;
            color: #333;
            font-size: 16px;
            line-height: 1.5;
            white-space: pre-wrap; /* 메모의 줄바꿈을 유지 */
          }
          button {
            margin-top: 20px;
            padding: 8px 16px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            font-size: 14px;
          }
          button:hover {
            background-color: #0056b3;
          }
        }
      `}</style>
    </div>
  );
};

export default FunnelsComponent;
