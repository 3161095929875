function Footer() {
  return (
    <footer className="footer">
      <div>
        <h1>
          <img
            src={`${process.env.PUBLIC_URL}/Images/funnels_logo_white.png`}
            alt="funnels"
          />
        </h1>
        <p className="copyright">
          Copyright &copy; Funnels All rights reserved.
        </p>
        <p>
          대표 원종현 | 사업자등록번호 127-88-03197
          <br />
          주소 인천광역시 서구 원당대로 1045, 811호
          811~812호(원당동,금호헤리티지7)
          <br />
          대표번호 031-123-4567 이메일 official@funnels.co.kr
        </p>
        <ul>
          <li>
            <a href="/">개인정보 처리방침</a>
          </li>
          <li>
            <a href="/">이용약관</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
export default Footer;
