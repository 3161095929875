import React from "react";

function HomeSix() {
  return (
    <section
      id="aboutSection"
      className="ninthContainer"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/newimgback.png)`,
      }}
    >
      <div className="sectionInner">
        <p className="ninthIntro sectionSubTitle">팀 소개</p>
        <h1 className="ninthToptitle sectionTitle">
          <span>10년의 모든 노하우</span>가<br className="mobile-br" />{" "}
          녹아져있는 퍼널스팀입니다
        </h1>
        <h2 className="ninthBottitle">파트별 전문가 집단 구성</h2>
        <div className="ninthCardBox">
          <div className="ninthBox">
            <h2 className="ninthTitle">퍼포먼스 팀</h2>
            <p className="ninthContent">
              온라인 DB마케팅을 주 축으로
              <br />
              빠른 매출을 성장시키는 핵심 부서입니다.
            </p>
          </div>
          <div className="ninthBox">
            <h2 className="ninthTitle">브랜드 팀</h2>
            <p className="ninthContent">
              데이터 기반 그로스해킹을 통해
              <br />
              치과 가치를 극대화 시킵니다.
            </p>
          </div>
          <div className="ninthBox">
            <h2 className="ninthTitle">CALL 팀</h2>
            <p className="ninthContent">
              15년차 치위생사가 팀을 직접 이끌며,
              <br />
              예약률 50% 이상을 유지하고 있습니다.
            </p>
          </div>
          <div className="ninthBox">
            <h2 className="ninthTitle">개발 팀</h2>
            <p className="ninthContent">
              기공 관리 프로그램 ‘덴토탈’을
              <br />
              자체 개발하여 운영 중입니다.{" "}
            </p>
          </div>
          <div className="ninthBox">
            <h2 className="ninthTitle">디자인 팀</h2>
            <p className="ninthContent">
              치과 디자인만 전문으로 진행하며,
              <br />
              수많은 디자인 레퍼런스를 보유하고 있습니다.{" "}
            </p>
          </div>
          <div className="ninthBox">
            <h2 className="ninthTitle">재무/인사 팀</h2>
            <p className="ninthContent">
              진료에만 집중할 수 있는 경영 환경을 <br />
              만들어 드립니다.{" "}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeSix;
