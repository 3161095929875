import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}

function Home3() {
  const width = useWindowWidth();
  const [shared, setShared] = useState(385.5);

  useEffect(() => {
    if (width < 576) {
      // setShared(300);
      setShared(150);
    } else {
      setShared(385.5);
    }
  }, [width]);
  // 슬릭 세팅
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: width / shared,
    //slidesToShow: 5.7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  const openModal = (imgSrc) => {
    setCurrentImg(imgSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImg("");
  };

  return (
    <section className="ThirdBox">
      <div className="sectionInner">
        <div className="ThirdHeader">
          <div className="sectionSubTitle">성공 레퍼런스</div>
          <h2 className="sectionTitle">
            퍼널스는 최종 매출로 <br className="mobile-br" />
            모든 걸 증명합니다
          </h2>
          <h6>
            <span className="chartHilipic">
              매출이 오르지 않을 경우 100% 환불해드립니다.
            </span>
          </h6>
        </div>
        {/* --------------------------------------- */}
        <div className="graphImgBox">
          <img
            src={`${process.env.PUBLIC_URL}/Images/2/newGraph.png`}
            alt="funnels"
          />
        </div>
        {/* --------------------------------------- */}
        <div className="slider-container">
          {/* <div className="ThirdFooter"> */}
          <Slider {...settings}>
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDA.png"}
              alt="CARDA 이미지"
              onClick={() => openModal("/Images/2/3.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDB.png"}
              alt="CARDB 이미지"
              onClick={() => openModal("/Images/2/5.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDC.png"}
              alt="CARDC 이미지"
              onClick={() => openModal("/Images/2/2.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDD.png"}
              alt="CARDD 이미지"
              onClick={() => openModal("/Images/2/1.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDE.png"}
              alt="CARDE 이미지"
              onClick={() => openModal("/Images/2/4.jpeg")}
            />
            <div className="cardDiv">
              <p className="highlighted-text">
                <span className="highlight">퍼널스</span>와 함께 할<br /> 파트너
                원장님을 찾습니다.
              </p>
              <button className="partnerBtn">
                <Link to="contactSection" smooth={true} duration={700}>
                  파트너 신청하기
                </Link>
              </button>
            </div>
          </Slider>
        </div>{" "}
      </div>
      {modalOpen && (
        <div className="threeModal" onClick={closeModal}>
          <div
            className="threeModal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="threeModal-close" onClick={closeModal}>
              ×
            </button>
            <img src={process.env.PUBLIC_URL + currentImg} alt="모달 이미지" />
          </div>
        </div>
      )}
    </section>
  );
}

export default Home3;
