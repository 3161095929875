import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-scroll";
import React, { useEffect, useState } from "react";

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    // cleanup function to remove the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return width;
}

function Graph() {
  const dataPoints = [
    { date: "2022년 3월 (개원)", value: 0 },
    { date: "2022년 7월", value: 2.7 },
    { date: "2022년 12월", value: 4.1 },
    { date: "2023년 6월", value: 7.3 },
  ];

  const maxValue = 7.3;

  const points = dataPoints
    .map((point, index) => {
      const x = (index / (dataPoints.length - 1)) * 100;
      const y = 100 - (point.value / maxValue) * 75; // bottom 75%와 맞추기 위해
      return `${x},${y}`;
    })
    .join(" ");
  // Highlight 상태 관리

  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    const handleMove = (event) => {
      // 마우스와 터치 이벤트에서 Y 좌표를 얻음
      const clientY = event.touches ? event.touches[0].clientY : event.clientY;
      // 현재 스크롤 위치를 고려하여 마우스 Y 좌표를 계산
      const mouseYWithScroll = clientY + window.scrollY;
      // 화면 너비 확인
      const screenWidth = window.innerWidth;

      // 너비가 600px 이하일 때와 그 이상일 때의 마우스 Y 좌표 조건
      if (screenWidth <= 600) {
        if (mouseYWithScroll >= 1640) {
          setHighlight(true);
        }
      } else {
        if (mouseYWithScroll >= 2500) {
          setHighlight(true);
        }
      }
    };

    // 마우스 이동 및 터치 이동 이벤트 리스너 추가
    window.addEventListener("mousemove", handleMove);
    window.addEventListener("touchmove", handleMove);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("mousemove", handleMove);
      window.removeEventListener("touchmove", handleMove);
    };
  }, []);

  return (
    <div className="graph-container">
      <div className="chart-container">
        <div className="chart-box">
          <svg
            className="chart-svg"
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient
                id="grad"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#0275E6" />
                <stop offset="1" stopColor="#93E4F5" />
              </linearGradient>
            </defs>

            <path
              id="linePath"
              d={`M ${points}`}
              fill="none"
              stroke="url(#grad)" // 그라데이션 적용
              strokeWidth="1"
              strokeDasharray={`${points.length * 10}`}
            >
              {
                <animate
                  attributeName="stroke-dashoffset"
                  from={`${points.length * 10}`} // 시작 값
                  to="0"
                  dur="5s"
                  fill="freeze"
                  repeatCount="indefinite"
                />
              }
            </path>
          </svg>

          {dataPoints.map((point, index) => (
            <div
              key={index}
              className="data-point"
              style={{
                left: `${(index / (dataPoints.length - 1)) * 100}%`,
                bottom: `${(point.value / maxValue) * 75}%`,
              }}
            >
              <div className="point-label">{point.value}억원</div>
            </div>
          ))}
          {/* 선 연결을 위한 SVG */}
        </div>
        <div className="text-box">
          <div>
            <h4 className="fontEn">Performance Marketing</h4>
            <p>(DB 마케팅, 제휴영업 등)</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="38"
            viewBox="0 0 17 38"
            fill="none"
          >
            <path d="M1 1L16 19L1 37" stroke="white" stroke-opacity="0.3" />
          </svg>
          <div>
            <h4 className="fontEn">Data Mining</h4>
            <p>(콜센터, 상담실, 데스크 맞춤 솔루션)</p>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="38"
            viewBox="0 0 17 38"
            fill="none"
          >
            <path d="M1 1L16 19L1 37" stroke="white" stroke-opacity="0.3" />
          </svg>
          <div>
            <h4 className="fontEn">Search Marketing</h4>
            <p>(네이버플레이스,검색광고,커뮤니티 등)</p>
          </div>
        </div>
        <p>
          MSO 계약 14개월만에,
          <br />
          <span className={`chartHili ${highlight ? "highlight" : ""}`}>
            월 매출 7억
          </span>{" "}
          ○○치과
        </p>
        <p>월 평균 이익률 36%</p>
      </div>
      <div className="date-box">
        {dataPoints.map((point, index) => (
          <div key={index} className="date-label">
            {point.date}
          </div>
        ))}
      </div>
    </div>
  );
}

function HomeThree() {
  const width = useWindowWidth();
  const [shared, setShared] = useState(385.5);

  useEffect(() => {
    if (width < 576) {
      // setShared(300);
      setShared(150);
    } else {
      setShared(385.5);
    }
  }, [width]);
  // 슬릭 세팅
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: width / shared,
    //slidesToShow: 5.7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  const openModal = (imgSrc) => {
    setCurrentImg(imgSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentImg("");
  };

  return (
    <section
      className="Third"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/Images/2/bg_graph.png)`,
      }}
    >
      <div className="sectionInner">
        <div className="ThirdHeader">
          <div className="sectionSubTitle">성공 레퍼런스</div>
          <h2 className="sectionTitle">
            퍼널스는 최종 매출로 <br className="mobile-br" />
            모든 걸 증명합니다
          </h2>
          <h6>
            <span className="chartHilipic">
              매출이 오르지 않을 경우 100% 환불해드립니다.
            </span>
          </h6>
        </div>
        {/* --------------------------------------- */}
        {/* <div className="ThirdBody"> */}
        <Graph />
        {/* </div> */}
        {/* --------------------------------------- */}
        <div className="slider-container">
          {/* <div className="ThirdFooter"> */}
          <Slider {...settings}>
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDA.png"}
              alt="CARDA 이미지"
              onClick={() => openModal("/Images/2/3.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDB.png"}
              alt="CARDB 이미지"
              onClick={() => openModal("/Images/2/5.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDC.png"}
              alt="CARDC 이미지"
              onClick={() => openModal("/Images/2/2.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDD.png"}
              alt="CARDD 이미지"
              onClick={() => openModal("/Images/2/1.jpeg")}
            />
            <img
              src={process.env.PUBLIC_URL + "/Images/2/CARDE.png"}
              alt="CARDE 이미지"
              onClick={() => openModal("/Images/2/4.jpeg")}
            />
            <div className="cardDiv">
              <p className="highlighted-text">
                <span className="highlight">퍼널스</span>와 함께 할<br /> 파트너
                원장님을 찾습니다.
              </p>
              <button className="partnerBtn">
                <Link to="contactSection" smooth={true} duration={700}>
                  파트너 신청하기
                </Link>
              </button>
            </div>
          </Slider>
        </div>{" "}
      </div>
      {modalOpen && (
        <div className="threeModal" onClick={closeModal}>
          <div
            className="threeModal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="threeModal-close" onClick={closeModal}>
              ×
            </button>
            <img src={process.env.PUBLIC_URL + currentImg} alt="모달 이미지" />
          </div>
        </div>
      )}
    </section>
  );
}

export default HomeThree;
