import React from "react";

function HomeFirst() {
  return (
    <section
      className="video-container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/funnelsMain.png)`,
      }}
    >
      <div className="content">
        <p className="AddArticle">
          광고비 대비 5배 이상 매출 발생
          <span> (최근 6개월)</span> <br /> 월 30억 치과, 개원치과 월 매출 7억
        </p>
        <div className="line"></div>
        <p className="MainArticle">
          <span className="highlight marginBottom3px">치과 마케팅만 10년,</span>
        </p>
        <p className="SubArticle">
          <span>
            퍼포먼스 결과는
            <br className="mobile-br" /> 다를 수 밖에 없습니다.
          </span>
        </p>
        <div className="MSO">
          <p>Online Marketing / MSO</p>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
            >
              <path
                d="M1 1L9.67857 11L19 1"
                stroke="#003D99"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeFirst;
